import * as React from 'react';

export function BodyButton({ onClick, styles, className }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      css={styles}
      viewBox="0 0 904 643"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="button">
        <g id="shade">
          <path
            id="path14"
            d="M86.4003 71.5442L66.251 512.457L398.558 527.643L458.322 643.021L528.362 533.575L856.06 548.549L876.208 107.638L86.4003 71.5442Z"
            fill="#F7909F"
          />
          <path
            id="path18"
            d="M29.998 61.3367L49.6367 502.273L381.962 487.471L451.874 596.998L511.773 481.689L839.489 467.091L819.85 26.1567L29.998 61.3367Z"
            fill="#F7909F"
          />
          <path
            id="path194"
            d="M428.522 19.2502L816.418 -0.252455L817.052 12.3622L429.157 31.8662L428.522 19.2502Z"
            fill="#F7909F"
          />
          <path
            id="path198"
            d="M255.976 27.9263L406.957 20.3357L407.592 32.9503L256.612 40.541L255.976 27.9263Z"
            fill="#F7909F"
          />
          <path
            id="path202"
            d="M202.491 30.6154L232.694 29.0968L233.329 41.7128L203.126 43.2314L202.491 30.6154Z"
            fill="#F7909F"
          />
          <path
            id="path206"
            d="M883.534 552.281L868.884 551.612L883.798 225.228L898.45 225.897L883.534 552.281Z"
            fill="#F7909F"
          />
          <path
            id="path210"
            d="M900.01 191.768L885.359 191.098L889.032 110.7L903.683 111.369L900.01 191.768Z"
            fill="#F7909F"
          />
          <path
            id="path214"
            d="M9.29492 277.67L-0.34375 61.2646L14.3069 60.6126L23.9456 277.018L9.29492 277.67Z"
            fill="#F7909F"
          />
        </g>
        <path
          id="back"
          d="M53.0161 75.7572V517.129H385.668L450.639 629.661L515.608 517.129H843.649V75.7572H53.0161Z"
          fill="#F14356"
        />
        <g id="shadow-01">
          <path
            id="path30"
            d="M115.868 239.95H127.178V186.268H152.305V239.95H163.617V176.069H115.868V239.95Z"
            fill="#F7909F"
          />
          <path
            id="path42"
            d="M218.225 240.877C236.397 240.877 250.676 226.136 250.676 208.056C250.676 189.977 236.397 175.328 218.225 175.328C199.96 175.328 185.774 189.977 185.774 208.056C185.774 226.136 199.96 240.877 218.225 240.877ZM218.132 230.214C205.986 230.214 197.178 220.294 197.178 208.056C197.178 195.818 205.986 185.897 218.132 185.897C230.37 185.897 239.364 195.818 239.364 208.056C239.364 220.294 230.37 230.214 218.132 230.214V230.214Z"
            fill="#F7909F"
          />
          <path
            id="path46"
            d="M317.987 176.069H276.636V210.652C276.636 224.56 272.185 230.679 265.417 230.679H264.489V253.208H275.801V239.951H312.145V253.208H323.549V231.884H317.987V176.069ZM287.947 186.176H306.676V229.844H282.477C286.371 226.228 287.947 219.645 287.947 212.413V186.176Z"
            fill="#F7909F"
          />
          <path
            id="path50"
            d="M356.927 213.712H381.403V203.42H356.927V186.176H384.927V176.069H345.708V239.95H385.854V229.844H356.927V213.712Z"
            fill="#F7909F"
          />
          <path
            id="path54"
            d="M414.224 214.824C414.224 226.228 409.959 229.844 403.376 229.844H402.079V239.949H405.695C417.562 239.949 425.535 231.513 425.535 215.009V186.175H444.264V239.949H455.576V176.069H414.224V214.824Z"
            fill="#F7909F"
          />
          <path
            id="path58"
            d="M493.126 218.718V176.069H481.815V239.95H492.106L517.602 196.93V239.95H528.914V176.069H518.252L493.126 218.718Z"
            fill="#F7909F"
          />
          <path
            id="path62"
            d="M548.847 176.069V186.176H567.113V239.95H578.425V186.176H596.688V176.069H548.847Z"
            fill="#F7909F"
          />
          <path
            id="path66"
            d="M627.933 199.341V176.069H616.622V239.95H641.655C655.933 239.95 661.959 229.658 661.959 219.46C661.959 209.076 655.841 199.341 641.098 199.341H627.933ZM627.933 229.844V209.076H639.337C647.31 209.076 650.647 213.618 650.647 219.46C650.647 225.394 647.031 229.844 640.821 229.844H627.933Z"
            fill="#F7909F"
          />
          <path
            id="path70"
            d="M711.191 240.785C721.667 240.785 730.846 235.964 736.595 228.361L728.064 221.964C724.171 226.97 718.423 230.216 711.47 230.216C699.138 230.216 690.144 220.294 690.144 208.057C690.144 195.818 699.324 185.897 711.47 185.897C718.423 185.897 724.171 189.142 728.064 194.242L736.595 187.752C730.846 180.242 721.667 175.328 711.191 175.328C693.204 175.328 678.74 189.977 678.74 208.057C678.74 226.136 693.204 240.785 711.191 240.785Z"
            fill="#F7909F"
          />
          <path
            id="path74"
            d="M774.237 176.069C759.959 176.069 753.932 186.361 753.932 196.56C753.932 205.645 758.66 214.268 769.787 216.308L753.099 239.95H766.448L782.209 216.678H787.959V239.95H799.269V176.069H774.237ZM776.555 207.129C768.581 207.129 765.244 202.585 765.244 196.745C765.244 190.904 768.86 186.176 775.071 186.176H787.959V207.129H776.555Z"
            fill="#F7909F"
          />
        </g>
        <g id="shadow-02">
          <path
            id="path82"
            d="M206.246 313.169H191.057L159.105 381.087L127.009 313.169H111.819L99.7832 411.895H117.265L125.289 345.696L152.083 400.719H166.269L192.921 345.552L200.801 411.895H218.282L206.246 313.169Z"
            fill="#F7909F"
          />
          <path
            id="path86"
            d="M296.658 355.009H255.678V313.169H238.197V411.895H255.678V370.628H296.658V411.895H314.139V313.169H296.658V355.009Z"
            fill="#F7909F"
          />
          <path
            id="path90"
            d="M356.695 371.344H394.523V355.439H356.695V328.788H399.969V313.169H339.358V411.895H401.401V396.276H356.695V371.344Z"
            fill="#F7909F"
          />
          <path
            id="path94"
            d="M479.636 355.009H438.656V313.169H421.173V411.895H438.656V370.628H479.636V411.895H497.116V313.169H479.636V355.009Z"
            fill="#F7909F"
          />
          <path
            id="path98"
            d="M539.814 379.083V313.169H522.334V411.895H538.238L577.642 345.409V411.895H595.124V313.169H578.645L539.814 379.083Z"
            fill="#F7909F"
          />
          <path
            id="path102"
            d="M637.678 371.344H675.506V355.439H637.678V328.788H680.951V313.169H620.34V411.895H682.383V396.276H637.678V371.344Z"
            fill="#F7909F"
          />
          <path
            id="path106"
            d="M803.317 313.169H788.129L756.177 381.087L724.08 313.169H708.892L696.854 411.895H714.337L722.36 345.696L749.156 400.719H763.341L789.992 345.552L797.873 411.895H815.353L803.317 313.169Z"
            fill="#F7909F"
          />
        </g>
        <g id="word-01">
          <path
            id="path114"
            d="M110.815 235.616H122.126V181.934H147.252V235.616H158.564V171.735H110.815V235.616Z"
            fill="white"
          />
          <path
            id="path126"
            d="M213.172 236.544C231.344 236.544 245.623 221.803 245.623 203.723C245.623 185.644 231.344 170.995 213.172 170.995C194.907 170.995 180.722 185.644 180.722 203.723C180.722 221.803 194.907 236.544 213.172 236.544ZM213.079 225.881C200.934 225.881 192.126 215.961 192.126 203.723C192.126 191.485 200.934 181.564 213.079 181.564C225.318 181.564 234.311 191.485 234.311 203.723C234.311 215.961 225.318 225.881 213.079 225.881V225.881Z"
            fill="white"
          />
          <path
            id="path130"
            d="M312.934 171.735H271.584V206.318C271.584 220.226 267.133 226.345 260.365 226.345H259.437V248.874H270.749V235.617H307.093V248.874H318.497V227.55H312.934V171.735ZM282.894 181.842H301.624V225.51H277.425C281.318 221.894 282.894 215.311 282.894 208.079V181.842Z"
            fill="white"
          />
          <path
            id="path134"
            d="M351.874 209.379H376.35V199.087H351.874V181.843H379.874V171.736H340.656V235.617H380.801V225.511H351.874V209.379Z"
            fill="white"
          />
          <path
            id="path138"
            d="M409.172 210.491C409.172 221.895 404.906 225.511 398.324 225.511H397.026V235.616H400.642C412.509 235.616 420.482 227.18 420.482 210.676V181.842H439.212V235.616H450.524V171.736H409.172V210.491Z"
            fill="white"
          />
          <path
            id="path142"
            d="M488.073 214.385V171.736H476.762V235.617H487.053L512.549 192.597V235.617H523.861V171.736H513.2L488.073 214.385Z"
            fill="white"
          />
          <path
            id="path146"
            d="M543.794 171.736V181.843H562.06V235.617H573.372V181.843H591.636V171.736H543.794Z"
            fill="white"
          />
          <path
            id="path150"
            d="M622.88 195.007V171.735H611.57V235.616H636.603C650.88 235.616 656.907 225.324 656.907 215.126C656.907 204.742 650.788 195.007 636.046 195.007H622.88ZM622.88 225.51V204.742H634.284C642.258 204.742 645.595 209.284 645.595 215.126C645.595 221.06 641.979 225.51 635.768 225.51H622.88Z"
            fill="white"
          />
          <path
            id="path154"
            d="M706.138 236.451C716.614 236.451 725.793 231.63 731.542 224.027L723.011 217.63C719.118 222.637 713.37 225.882 706.417 225.882C694.085 225.882 685.091 215.961 685.091 203.723C685.091 191.485 694.271 181.563 706.417 181.563C713.37 181.563 719.118 184.809 723.011 189.909L731.542 183.418C725.793 175.909 716.614 170.994 706.138 170.994C688.151 170.994 673.688 185.643 673.688 203.723C673.688 221.802 688.151 236.451 706.138 236.451Z"
            fill="white"
          />
          <path
            id="path158"
            d="M769.185 171.736C754.906 171.736 748.88 182.028 748.88 192.227C748.88 201.312 753.608 209.935 764.734 211.975L748.046 235.617H761.396L777.157 212.345H782.906V235.617H794.217V171.736H769.185ZM771.502 202.796C763.529 202.796 760.192 198.252 760.192 192.412C760.192 186.571 763.808 181.843 770.018 181.843H782.906V202.796H771.502Z"
            fill="white"
          />
        </g>
        <g id="word-02">
          <path
            id="path166"
            d="M201.193 308.836H186.004L154.052 376.754L121.956 308.836H106.766L94.7305 407.562H112.212L120.236 341.363L147.03 396.386H161.216L187.868 341.219L195.748 407.562H213.229L201.193 308.836Z"
            fill="white"
          />
          <path
            id="path170"
            d="M291.605 350.676H250.625V308.836H233.144V407.562H250.625V366.295H291.605V407.562H309.087V308.836H291.605V350.676Z"
            fill="white"
          />
          <path
            id="path174"
            d="M351.643 367.011H389.471V351.106H351.643V324.455H394.916V308.836H334.305V407.562H396.348V391.943H351.643V367.011Z"
            fill="white"
          />
          <path
            id="path178"
            d="M474.583 350.676H433.603V308.836H416.121V407.562H433.603V366.295H474.583V407.562H492.063V308.836H474.583V350.676Z"
            fill="white"
          />
          <path
            id="path182"
            d="M534.762 374.749V308.835H517.282V407.561H533.186L572.59 341.075V407.561H590.071V308.835H573.592L534.762 374.749Z"
            fill="white"
          />
          <path
            id="path186"
            d="M632.625 367.011H670.453V351.106H632.625V324.455H675.898V308.836H615.288V407.562H677.33V391.943H632.625V367.011Z"
            fill="white"
          />
          <path
            id="path190"
            d="M798.264 308.836H783.076L751.124 376.754L719.027 308.836H703.839L691.802 407.562H709.284L717.307 341.363L744.103 396.386H758.288L784.939 341.219L792.82 407.562H810.3L798.264 308.836Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
}
