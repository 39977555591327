import * as React from 'react';

interface HTMLProps {
  className?: string;
  css?: any;
  key?: any;
}

export const HTML: React.FC<HTMLProps> = ({ children, ...props }) => {
  if (typeof children !== 'string') {
    return null;
  }

  return <div {...props} dangerouslySetInnerHTML={{ __html: children }} />;
};

export const SpanHTML: React.FC<HTMLProps> = ({ children }) => {
  if (typeof children !== 'string') {
    return null;
  }
  return (
    <span
      dangerouslySetInnerHTML={{ __html: children.replace(/<\/?p/g, '<span') }}
    />
  );
};

export const HTMLRef: React.FC<HTMLProps> = React.forwardRef(
  ({ children, ...props }, ref: any) => {
    if (typeof children !== 'string') {
      return null;
    }
    return (
      <div
        ref={ref}
        {...props}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    );
  }
);
