import * as React from 'react';
import * as uuid from 'uuid/v1';
import { css } from '@emotion/core';
import { Button } from '../button/index';
import { HTML } from '../html/index';
import { Img } from '../img/index';
import { Counter } from '../counter/index';

import { BodyButton } from './button';
const buttonStyles = css`
  & * {
    transition: all 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover {
    & #shade {
      fill: #f14356;
    }
    & #back {
      fill: #29b2c0;
    }
  }
`;

export const Landing: React.FC<{ data: any }> = ({ data }) => {
  return (
    <div className="text-gray-900">
      <div className="relative w-full pb-2/5">
        <Img
          className="inset-0 opacity-75"
          src={data.image}
          style={{ position: 'absolute' }}
        />
        <div className="absolute inset-0 flex flex-col justify-end max-w-xl px-4 py-8 md:px-12">
          <HTML className="text-6xl font-bold">{data.title.html}</HTML>
          <HTML className="text-2xl font-medium">{data.description.html}</HTML>
        </div>
      </div>
      <div className="flex flex-row flex-wrap items-center justify-around px-4 mt-12">
        {data.fastlinks.map(({ linktext, link, linktarget }) => (
          <Button
            className="flex-shrink-0 mx-4 mb-8"
            component="a"
            key={uuid()}
            rounded={12}
            inverted
            target={link.target && link.target}
            href={link.url ? link.url : `#${linktarget}`}
            css={css`
              box-shadow: 0 0 0 8px #f7909f;
              &:hover {
                box-shadow: 0 0 0 16px #f7909f;
              }
              transition: box-shadow 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
            `}
          >
            {linktext.text}
          </Button>
        ))}
      </div>
      <div className="mt-2 text-lg font-bold text-center uppercase">
        <Counter />
      </div>
      <div className="w-full max-w-lg mx-auto mt-8 text-sm leading-snug md:px-0">
        <HTML
          css={css`
            & p {
              margin-bottom: 1rem;
            }
          `}
        >
          {data.legend.html}
        </HTML>
      </div>
      <div className="flex flex-col flex-no-wrap w-full max-w-4xl px-4 mx-auto mt-8 md:flex-row">
        {data.stages.map(({ stage, state }, idx) => (
          <div
            key={uuid()}
            className={`p-4 relative ${
              state === 'Actual'
                ? 'bg-theme-green'
                : state === 'Future'
                ? 'bg-gray-400'
                : 'bg-theme-yellow'
            }`}
            css={css`
              box-shadow: inset -4px 0px 0 4px #fff;
            `}
          >
            <div
              className="absolute bottom-0 right-0 font-bold text-white"
              css={css`
                font-size: 7rem;
                line-height: 0.5;
              `}
            >
              {idx + 1}
            </div>
            <HTML
              className="relative"
              css={css`
                & h3 {
                  font-size: 2rem;
                  font-weight: 600;
                  line-height: 1.15;
                  margin-bottom: 0.75rem;
                }
              `}
            >
              {stage.html}
            </HTML>
          </div>
        ))}
      </div>
      <h2
        id="map"
        className="px-4 mt-12 text-3xl font-bold leading-tight text-center md:px-12"
      >
        {data.maptitle.text}
      </h2>
      <div className="flex flex-col flex-no-wrap max-w-4xl px-4 mx-auto mt-8 md:flex-row">
        {/* MapDescription MapImage MapLink */}
        <HTML
          className="flex-1 px-4"
          css={css`
            & h3 {
              font-size: 1.5rem;
              font-weight: 500;
              line-height: 1.15;
              margin-bottom: 0.75rem;
            }
            & p {
              margin-bottom: 2rem;
            }
          `}
        >
          {data.mapdescription.html}
        </HTML>
        <HTML
          className="flex-1 px-4"
          css={css`
            & h3 {
              font-size: 1.5rem;
              font-weight: 500;
              line-height: 1.15;
              margin-bottom: 0.75rem;
            }
            & p {
              margin-bottom: 2rem;
            }
          `}
        >
          {(data.maprules.html || '')
            .replace(
              'красные',
              '<span class="text-red-600 font-bold uppercase">красные</span>'
            )
            .replace(
              'синие',
              '<span class="text-blue-600 font-bold uppercase">синие</span>'
            )
            .replace(
              'зеленые',
              '<span class="text-green-600 font-bold uppercase">зеленые</span>'
            )}
        </HTML>
      </div>
      <div className="relative w-full mt-8 pb-1/2">
        <Img
          src={data.mapimage}
          className="inset-0 opacity-50"
          style={{ position: 'absolute' }}
        />
        <a
          className="absolute inset-0 flex items-center justify-center p-4"
          href={data.maplink.url && data.maplink.url}
          rel="noopener noreferrer"
          target="_blank"
        >
          <BodyButton
            className="cursor-pointer h-3/4"
            styles={buttonStyles}
            onClick={() => {}}
          />
        </a>
      </div>
      <div className="px-4 mt-8 text-center" id="quiz">
        <HTML className="text-3xl font-bold">{data.quiztitle.html}</HTML>
      </div>
      <div className="flex flex-col flex-no-wrap max-w-4xl px-4 mx-auto mt-8 md:flex-row">
        {data.quiz.map(({ quiztype, quizlink, quiztext }) => (
          <div key={uuid()} className="flex flex-col flex-1 px-4 mb-6">
            <Button
              className="flex-shrink-0 mb-4"
              component="a"
              rounded={12}
              inverted
              target={quizlink.target && quizlink.target}
              href={quizlink.url && quizlink.url}
              css={css`
                box-shadow: 0 0 0 8px #f7909f;
                &:hover {
                  box-shadow: 0 0 0 16px #f7909f;
                }
                transition: box-shadow 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
              `}
            >
              {quiztype.text}
            </Button>
            <HTML className="pl-1 text-sm leading-tight text-gray-700">
              {quiztext.html}
            </HTML>
          </div>
        ))}
      </div>
      <div className="max-w-md px-4 mx-auto mt-4" id="interview">
        <Button
          component="div"
          className="text-center"
          rounded={12}
          css={css`
            box-shadow: 0 0 0 8px #f7909f;
            &:hover {
              box-shadow: 0 0 0 16px #f7909f;
            }
            transition: box-shadow 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
            & a {
              display: inline-block;
            }
          `}
        >
          <HTML>{data.interview.html}</HTML>
        </Button>
      </div>
      <div className="max-w-lg px-4 mx-auto mt-12 md:px-0">
        <HTML
          css={css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            & h2 {
              width: 100%;
              font-size: 1.5rem;
              font-weight: 600;
              line-height: 1.15;
              margin-bottom: 2rem;
            }
            & p {
              flex: 0;
            }
            & a {
              white-space: nowrap;
            }
          `}
        >
          {data.socials.html}
        </HTML>
      </div>
      <div className="px-4 pt-8 mt-12 bg-gray-300">
        <HTML
          css={css`
            max-width: 32rem;
            margin: 2rem auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            & h2 {
              width: 100%;
              font-size: 1.25rem;
              font-weight: 600;
              line-height: 1.15;
              margin-bottom: 1rem;
              text-align: center;
            }
            & p {
              text-align: center;
              font-size: 0.825rem;
              width: 100%;
              margin-bottom: 0.5rem;
            }
            & .block-img {
              margin-top: 2rem;
              width: 30%;
              align-self: center;
              display: flex;
              align-items: center;
              justify-content: center;
              & a {
                overflow: hidden;
                display: block;
                width: 75%;
                border-radius: 50%;
                padding-bottom: 75%;
                position: relative;
                background: #fff;
              }
              & img {
                position: absolute;
                height: 100%;
                width: 100%;
                object-fit: contain;
              }
            }
          `}
        >
          {data.copy.html}
        </HTML>
        <div className="flex flex-row flex-no-wrap justify-between py-2 mx-auto mt-16 uppercase text-xxs">
          <div>
            <a href="https://orchestra-design.com" target="__blank">
              Orchestra Design
            </a>{' '}
            &copy; 2020
          </div>
          <div>
            Разработка:{' '}
            <a href="https://beta.accio.pro" target="__blank">
              ACCIO
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
