import * as React from 'react';
import { graphql } from 'gatsby';

import { Layout } from '../components/layout/index';
import { Landing } from '../components/landing/index';

function IndexPage({ data }) {
  return (
    <Layout>
      <Landing data={data.prismicLanding.data} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query IndexQuery {
    prismicLanding {
      data {
        copy {
          html
        }
        description {
          html
        }
        fastlinks {
          link {
            url
            target
          }
          linktarget
          linktext {
            text
          }
        }
        image {
          url
          alt
          fluid(maxWidth: 1920) {
            ...GatsbyPrismicImageFluid
          }
        }
        interview {
          html
        }
        legend {
          html
        }
        maptitle {
          text
        }
        mapdescription {
          html
        }
        maprules {
          html
        }
        mapimage {
          alt
          url
          fluid(maxWidth: 1920) {
            ...GatsbyPrismicImageFluid
          }
        }
        mapbutton {
          text
        }
        maplink {
          url
          target
        }
        quiz {
          quizlink {
            url
            target
          }
          quiztype {
            text
          }
          quiztext {
            html
          }
        }
        quiztitle {
          html
        }
        socials {
          html
        }
        stages {
          stage {
            html
          }
          state
        }
        title {
          html
        }
      }
    }
  }
`;

export default IndexPage;
