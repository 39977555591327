import * as React from 'react';
import { get } from 'lodash';
import GatsbyImage from 'gatsby-image';

import { Image as ImageProps } from '../../typings/image';

const holderClasses = `
  flex items-center justify-center
  bg-white
  h-full w-full
  overflow-hidden
`;

interface ImgProps {
  src: ImageProps;
  className?: string;
  css?: any;
  style?: any;
}

export const Img: React.FC<ImgProps> = ({ src, ...props }) => {
  const imageSharp = get(src, 'localFile.childImageSharp');
  const fluid = get(imageSharp, 'fluid', get(src, 'fluid'));
  const fixed = get(imageSharp, 'fixed', get(src, 'fixed'));
  const url = get(src, 'url');

  if (fluid) {
    return <GatsbyImage fluid={fluid} {...props} />;
  }

  if (fixed) {
    return <GatsbyImage fixed={fixed} {...props} />;
  }

  if (url) {
    return (
      <img
        className="w-full"
        src={url}
        {...props}
        alt={get(src, 'alt', '')}
        loading="lazy"
      />
    );
  }

  return <div className={holderClasses} {...props} />;
}
