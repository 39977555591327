import * as React from 'react';
import * as axios from 'axios';
import useSpring from 'react-use/lib/useSpring';

function getNoun(number, one, two, five) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
}

export const Counter: React.FC = () => {
  const [count, setCount] = React.useState(0);
  const value = Math.floor(useSpring(count, 10, 7));

  React.useEffect(() => {
    axios
      .get(`/api/count`)
      .then(function (response) {
        if (response.data.markers !== 0) {
          setCount((prev) => prev + response.data.count);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <div>
      уже приняло участие: {isNaN(value) ? 0 : value}
      {' '}
      {getNoun(isNaN(value) ? 0 : value, 'человек', 'человека', 'человек')}
    </div>
  );
};

export default Counter;
